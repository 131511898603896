// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const LINE_HEIGHT = 22;
export const LABEL_PADDINGS = 5;

export const CELL_WIDTH = 80;

export const START_POINT_SPAN_HEIGHT = 18;
export const SEGMENT_SPAN_HEIGHT = 26;
export const MIN_ROW_HEIGHT = SEGMENT_SPAN_HEIGHT + 2 * START_POINT_SPAN_HEIGHT;
export const SPAN_MARGIN = 2;

export const SIDE_PANEL_WIDTH = 400;
